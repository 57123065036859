import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import { Title, Flex, Text, Grid, Box, Label } from "../utils/system";
import { IoCalendar } from "react-icons/io5";
import Header from "../components/Header";

const Events = ({ data }) => {
  const events = data.allSanityEvent.nodes;
  return (
    <Layout>
      <Header title={"Events"} subtitle="Lovely to see you again" />
      <Flex mb={6}>
        <Grid
          gridGap={4}
          gridTemplateColumns={["1fr ", "1fr 1fr 1fr"]}
          width="90%"
        >
          {events &&
            events.map((item, key) => (
              <a href={item.link} target="_blank" key={key} rel="noreferrer">
                <Box>
                  <GatsbyImage
                    image={item.image.asset.gatsbyImageData}
                    alt={item.title}
                  />
                  <Title mb={1} fontSize={4}>
                    {item.title}
                  </Title>
                  <Text color="faded" mb={0} lineHeight={1.5}>
                    {item.description}
                  </Text>
                  <Flex justifyContent="flex-start">
                    <IoCalendar size={20} />
                    <Text ml={2} fontWeight="bold">
                      {item.date}
                    </Text>
                  </Flex>
                  <Label m={0} color="blue">
                    {item.category}
                  </Label>
                </Box>
              </a>
            ))}
        </Grid>
      </Flex>
    </Layout>
  );
};

export default Events;

export const pageQuery = graphql`
  query Events {
    allSanityEvent {
      nodes {
        title
        category
        date
        link
        image {
          asset {
            gatsbyImageData(aspectRatio: 1.6)
          }
        }
        description
      }
    }
  }
`;
